import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="vega"
export default class extends Controller {
  static values = {
    spec: String,
    element: String,
    spark: Array,
    bullet: Array,
    spectrum: Array
  }

  connect() {
    const div = document.createElement('div');

    if (this.specValue > "") {
      const el = document.getElementById(this.elementValue);
      el?.firstElementChild?.classList.add('blur-lg')
      vegaEmbed(div, this.specValue, { actions: false }).then(function (result) {
        el.replaceChildren(div);
        el.value = result.view // Access the Vega view instance (https://vega.github.io/vega/docs/api/view/)
      }).catch(console.error);
    }

    if (this.sparkValue.length > 0) {
      const el = this.element;
      console.log(this.sparkValue);
      vegaEmbed(div, this.sparkline(this.sparkValue), { actions: false }).then(function (result) {
        el.replaceChildren(div);
        el.value = result.view // Access the Vega view instance (https://vega.github.io/vega/docs/api/view/)
      }).catch(console.error);
    }

    if (this.bulletValue.length > 0) {
      const el = this.element;
      console.log(this.bulletValue);
      vegaEmbed(div, this.bullet(this.bulletValue), { actions: false }).then(function (result) {
        el.replaceChildren(div);
        el.value = result.view // Access the Vega view instance (https://vega.github.io/vega/docs/api/view/)
      }).catch(console.error);
    }

    if (this.spectrumValue.length > 0) {
      const el = this.element;
      console.log(this.spectrumValue);
      vegaEmbed(div, this.spectrum(this.spectrumValue), { actions: false }).then(function (result) {
        el.replaceChildren(div);
        el.value = result.view // Access the Vega view instance (https://vega.github.io/vega/docs/api/view/)
      }).catch(console.error);
    }
  }

  sparkline(values) {
    return {
      $schema: "https://vega.github.io/schema/vega-lite/v5.json",
      data: {
        values: [{ q: values }],
        name: "dataset"
      },

      transform: [
        {
          flatten: ["q"]
        },
        {
          window: [{
            op: "rank",
            as: "index"
          }]
        }
      ],

      height: 20,
      width: 100,
      padding: 0,

      mark: {
        type: "area",
        interpolate: "monotone",
        line: { color: "steelblue", strokeWidth: 1, interpolate: "monotone" },
        fill: {
          x1: 1,
          y1: 1,
          x2: 1,
          y2: 0,
          gradient: "linear",
          stops: [
            {
              offset: 0,
              color: "#B0C4DE20"
            },
            {
              offset: 1,
              color: "#B0C4DE"
            }
          ]
        }
      },
      encoding: {
        x: {
          field: "index",
          type: "quantitative",
          axis: {
            title: null,
            orient: "top",
            domain: false,
            ticks: false,
            labels: false,
            grid: false
          }
        },
        y: {
          field: "q",
          type: "quantitative",
          axis: {
            title: null,
            domain: false,
            labels: false,
            ticks: false,
            grid: false
          }
        }
      },

      config: {
        view: { stroke: "", fill: "", fillOpacity: 0 },
        header: {
          title: null
        }
      }
    }
  }

  bullet(values) {
    return {
      $schema: "https://vega.github.io/schema/vega-lite/v5.json",

      data: {
        values: [{
          lower: values[0],
          q1: values[1],
          median: values[2],
          q3: values[3],
          upper: values[4],
          average: values[5],
          b1: 200,
          b2: 400,
          b3: 600
        }]
      },

      height: 20,
      width: 100,
      padding: 0,

      layer: [
        {
          mark: { type: "bar", color: "#f0f0f0", clip: true },
          encoding: { x: { field: "b3", type: "quantitative", scale: { domain: [0, 600] }, title: null } }
        },
        {
          mark: { type: "bar", color: "#e8e8e8" },
          encoding: { x: { field: "b2", type: "quantitative" } }
        },
        {
          mark: { type: "bar", color: "#e0e0e0" },
          encoding: { x: { field: "b1", type: "quantitative" } }
        },
        {
          mark: { type: "bar", size: 8 },
          encoding: {
            x: { field: "lower", type: "quantitative" },
            x2: { field: "upper" },
            color: { value: "lightsteelblue" }
          }
        },
        {
          mark: { type: "bar", size: 12 },
          encoding: {
            x: { field: "q1", type: "quantitative" },
            x2: { field: "q3" },
            color: { value: "steelblue" }
          }
        },
        {
          mark: { type: "tick", color: "white", size: 14 },
          encoding: {
            x: {
              field: "median", type: "quantitative",
              axis: {
                domain: false,
                ticks: false,
                labels: false,
                grid: false
              }
            }
          }
        },
        {
          mark: { type: "tick", color: "black", size: 20, thickness: 2 },
          encoding: { x: { field: "average", type: "quantitative" } }
        }
      ],
      config: { view: { stroke: "", fill: "" } }
    }
  }

  spectrum(values) {
    return {
      $schema: "https://vega.github.io/schema/vega-lite/v5.json",

      height: 15,
      width: 100,
      padding: 0,

      data: {
        values: { f: values }
      },
      transform: [
        { flatten: ["f"] },
        { window: [{ op: "rank", as: "index" }] },
        { calculate: "datum.index - 1", as: "zero_based_index" }
      ],
      mark: "rect",
      encoding: {
        x: {
          field: "index",
          type: "quantitative",
          title: null,
          axis: null,
          scale: {domain: [0, 30]}
        },
        x2: { field: "zero_based_index" },
        color: {
          field: "f",
          type: "quantitative",
          title: null,
          legend: null
        }
      },
      config: {
        view: { stroke: "transparent", fill: "", fillOpacity: 0 }
      }
    }
  }
}

