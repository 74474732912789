import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

import Dropdown from '@stimulus-components/dropdown'
application.register('dropdown', Dropdown)

import AutoSubmit from '@stimulus-components/auto-submit'
application.register('auto-submit', AutoSubmit)

import CheckboxSelectAll from '@stimulus-components/checkbox-select-all'
application.register('checkbox-select-all', CheckboxSelectAll)

export { application }
