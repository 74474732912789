import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "button"]

  connect() {
    console.log("Expandable controller connected")
  }

  toggle() {
    console.log("Toggle method called")
    this.contentTarget.classList.toggle("truncate")
    this.contentTarget.classList.toggle("max-w-[150px]")
    
    this.buttonTarget.textContent = this.contentTarget.classList.contains("truncate") ? "Show More" : "Show Less"
  }
}
