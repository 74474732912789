const activeSelector = "[aria-selected='true']"

import { Controller } from "@hotwired/stimulus"

export default class Multiselect extends Controller {
  static targets = ["hidden", "list", "search", "preview", "dropdown", "item", "addable"]

  static values = {
    items: Array,
    selected: Array,
    searchUrl: String,
    searchRemote: { type: Boolean, default: false },
    preloadUrl: String,
    addableUrl: String,
    disabled: { type: Boolean, default: false }
  }

  connect() {
    this.hiddenTarget.insertAdjacentHTML("afterend", this.template)
    if (this.selectedValue.length) this.selectedValueChanged()
    this.search = debounce(this.search.bind(this), 300)
    this.enhanceHiddenSelect()
    if (this.preloadUrlValue) this.preload()
  }

  // Allows selecting the hidden select field from html and extracting selected id values:
  // document.getElementById("selectId").values - [2, 4, 23]
  enhanceHiddenSelect() {
    Object.defineProperty(this.hiddenTarget, "values", {
      get: () => {
        if (this.selectedValue.length <= 0) return []

        return this.selectedValue.map(item => item.value)
      }
    })
  }

  search() {
    if (this.searchRemoteValue) return this.searchRemote()

    this.searchLocal()
  }

  async searchRemote() {
    if (this.searchTarget.value === "") return

    const response = await fetch(this.searchUrlValue + "?" + new URLSearchParams({
      q: this.searchTarget.value,
      preselects: this.selectedValue.map(x => x.value).join(",")
    }))

    const searchedItems = await response.json()

    this.itemsValue = searchedItems
    this.dropdownTarget.classList.remove("hidden")
  }

  searchLocal() {
    this.dropdownTarget.classList.remove("hidden")
    if (this.searchTarget.value === "") {
      let theRestOfTheItems = this.itemsValue.filter(x => !this.selectedValue.map(y => y.value).includes(x.value))
      this.listTarget.innerHTML = this.selectedItems
      this.listTarget.insertAdjacentHTML("beforeend", this.items(theRestOfTheItems))
    }

    let searched = this.itemsValue.filter(item => {
      return item.text.toLowerCase().includes(this.searchTarget.value.toLowerCase())
    })

    let selectedSearched = this.selectedValue.filter(item => {
      return item.text.toLowerCase().includes(this.searchTarget.value.toLowerCase())
    })

    searched = searched.filter(x => !selectedSearched.map(y => y.value).includes(x.value))

    if (searched.length === 0 && this.addableUrlValue) {
      return this.listTarget.innerHTML = this.noResultsTemplate
    }

    if (searched.length === 0) this.dropdownTarget.classList.add("hidden")
    this.listTarget.innerHTML = this.items(selectedSearched, true)
    this.listTarget.insertAdjacentHTML("beforeend", this.items(searched))
  }

  async preload() {
    const response = await fetch(this.preloadUrlValue)

    const items = await response.json()
    this.itemsValue = items
  }

  toggleDropdown() {
    if (!this.dropdownTarget.classList.contains("hidden")) {
      this.dropdownTarget.classList.add("hidden")
      this.searchTarget.blur()
    } else {
      this.dropdownTarget.classList.remove("hidden")
      this.searchTarget.focus()
    }
  }

  closeOnClickOutside({ target }) {
    if (this.element.contains(target)) return

    this.dropdownTarget.classList.add("hidden")
    this.searchTarget.value = ""
    if (!this.searchRemoteValue) {
      this.listTarget.innerHTML = this.allItems
      this.selectedValue.forEach(selected => {
        this.checkItem(selected.value)
      })
    }
  }

  searchUrlValueChanged() {
    if (this.searchUrlValue) this.searchRemoteValue = true
  }

  itemsValueChanged() {
    if (!this.hasListTarget) return

    if (this.itemsValue.length) {
      this.listTarget.innerHTML = this.items(this.itemsValue)
    } else {
      this.listTarget.innerHTML = this.noResultsTemplate
    }
  }

  selectedValueChanged() {
    if (!this.hasPreviewTarget) return

    while (this.hiddenTarget.options.length) this.hiddenTarget.remove(0)

    if (this.selectedValue.length > 0) {
      this.previewTarget.innerHTML = this.pills

      this.selectedValue.forEach(selected => {
        const option = document.createElement("option")
        option.text = selected.text
        option.value = selected.value
        option.setAttribute("selected", true)
        this.hiddenTarget.add(option)
      })

      if (!this.searchRemoteValue) {
        this.selectedValue.forEach(selected => {
          this.checkItem(selected.value)
        })
      }
    } else {
      this.previewTarget.innerHTML = this.placeholderTemplate
    }

    this.element.dispatchEvent(new Event("multiselect-change"))
  }

  removeItem(e) {
    e.stopPropagation()
    e.preventDefault()

    const itemToRemove = e.currentTarget.parentNode

    this.selectedValue = this.selectedValue.filter(x => x.value.toString() !== itemToRemove.dataset.value)
    this.uncheckItem(itemToRemove.dataset.value)
    this.element.dispatchEvent(new CustomEvent("multiselect-removed", { detail: { id: itemToRemove.dataset.value } }))
  }

  uncheckItem(value) {
    const itemToUncheck = this.listTarget.querySelector(`input[data-value="${value}"]`)

    if (itemToUncheck) itemToUncheck.checked = false
  }

  checkItem(value) {
    const itemToCheck = this.listTarget.querySelector(`input[data-value="${value}"]`)

    if (itemToCheck) itemToCheck.checked = true
  }

  toggleItem(input) {
    const item = {
      text: input.dataset.text,
      value: input.dataset.value
    }
    let newSelectedArray = this.selectedValue

    if (input.checked) {
      newSelectedArray.push(item)

      if (this.focusedItem) {
        this.focusedItem.removeAttribute("aria-selected")
      }

      input.setAttribute("aria-selected", "true")
      this.element.dispatchEvent(new CustomEvent("multiselect-added", { detail: { item: item } }))
    } else {
      newSelectedArray = newSelectedArray.filter(selected => selected.value.toString() !== item.value)
      this.element.dispatchEvent(new CustomEvent("multiselect-removed", { detail: { id: item.value } }))
    }

    this.selectedValue = newSelectedArray
  }

  onKeyDown(e) {
    const handler = this[`on${e.key}Keydown`]
    if (handler) handler(e)
  }

  onArrowDownKeydown = (event) => {
    const item = this.sibling(true)
    if (item) this.navigate(item)
    event.preventDefault()
  }

  onArrowUpKeydown = (event) => {
    const item = this.sibling(false)
    if (item) this.navigate(item)
    event.preventDefault()
  }

  onBackspaceKeydown = () => {
    if (this.searchTarget.value !== "") return
    if (!this.selectedValue.length) return

    const selected = this.selectedValue
    const value = selected.pop().value

    this.uncheckItem(value)
    this.selectedValue = selected
    this.element.dispatchEvent(new CustomEvent("multiselect-removed", { detail: { id: value } }))
  }

  onEnterKeydown = (e) => {
    if (this.focusedItem) this.focusedItem.click()
  }

  onEscapeKeydown = () => {
    if (this.searchTarget.value !== "") {
      this.searchTarget.value = ""
      return this.search()
    }

    this.toggleDropdown()
  }

  sibling(next) {
    const options = this.itemTargets
    const selected = this.focusedItem
    const index = options.indexOf(selected)
    const sibling = next ? options[index + 1] : options[index - 1]
    const def = next ? options[0] : options[options.length - 1]
    return sibling || def
  }

  async addable(e) {
    e.preventDefault()
    const query = this.searchTarget.value

    if (query === "" || this.itemsValue.some(item => item.text === query)) return

    const response = await fetch(this.addableUrlValue, {
      method: "POST",
      body: JSON.stringify({ addable: query })
    })
    if (response.ok) {
      const addedItem = await response.json()

      this.addAddableItem(addedItem)
    }
  }

  addAddableItem(addedItem) {
    this.itemsValue = this.itemsValue.concat(addedItem)
    this.selectedValue = this.selectedValue.concat(addedItem)
    this.searchTarget.value = ""
    this.element.dispatchEvent(new CustomEvent("multiselect-added", { detail: { item: addedItem } }))
  }

  navigate(target) {
    const previouslySelected = this.focusedItem
    if (previouslySelected) {
      previouslySelected.removeAttribute("aria-selected")
    }

    target.setAttribute("aria-selected", "true")
    target.scrollIntoView({ behavior: "smooth", block: "nearest" })
  }

  get focusedItem() {
    return this.listTarget.querySelector(activeSelector)
  }

  focusSearch() {
    this.searchTarget.focus()
  }

  addableEvent() {
    document.dispatchEvent(new CustomEvent("multiselect-addable"))
  }

  get template() {
    return `
      <div class="" data-multiselect-target="container" 
        data-action="click->multiselect#toggleDropdown 
        focus->multiselect#focusSearch" 
        tabindex="0" 
        data-turbo-cache="false"
      >
        <div class="flex flex-wrap w-full gap-2 gap-y-0 align-middle" data-multiselect-target="preview">
          ${this.placeholderTemplate}
        </div>
        
      </div>
      <div style="position: relative;" data-action="click@window->multiselect#closeOnClickOutside">
        <div class="hidden absolute shadow top-full bg-white dark:bg-form-input z-40 w-full left-0 rounded max-h-select overflow-y-auto" data-multiselect-target="dropdown">
          <div class="flex gap-2 flex-auto flex-wrap" data-multiselect-target="inputContainer">${this.inputTemplate}</div>
          <ul class="flex flex-col w-full" data-multiselect-target="list">
            ${this.allItems}
          </ul>
        </div>
      </div>
    `
  }

  get noResultsTemplate() {
    if (!this.addableUrlValue) return `<div class="p-3 text-gray-500">Nothing Found...</div>`
    return `
      <div class="p-3 text-gray-500">
        <span class="cursor-pointer underline p-2 text-gray-700" data-action="click->multiselect#addableEvent">
          ${this.element.dataset.addablePlaceholder}
        </span>
      </div>
    `
  }

  get inputTemplate() {
    return `
      <div class="flex-1 border-b border-stroke p-2">
        <input type="text" placeholder="${this.element.dataset.placeholder}" 
          data-multiselect-target="search" ${this.disabledValue === true ? 'disabled' : ''}
          data-action="multiselect#search keydown->multiselect#onKeyDown"
          class="w-full rounded-lg border-[1.5px] border-primary bg-transparent px-5 py-3 font-normal text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:bg-form-input dark:text-white h-full">
      </div>`
  }

  items(items, selected = false) {
    const checked = selected ? "checked" : ""
    let itemsTemplate = ""

    items.forEach(item => itemsTemplate += this.itemTemplate(item, checked))

    return itemsTemplate
  }

  get pills() {
    let itemsTemplate = ""

    this.selectedValue.forEach(item => itemsTemplate += this.pillTemplate(item))

    return itemsTemplate
  }

  get selectedItems() {
    return this.items(this.selectedValue, true)
  }

  get allItems() {
    return this.items(this.itemsValue)
  }

  itemTemplate(item, selected = "") {
    return `
      <li>
        <div class="cursor-pointer w-full border-stroke rounded-t border-b hover:bg-primary/5 dark:border-form-strokedark" @click="select(index,$event)">
          <div class="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative">
            <label class="w-full items-center flex">
              <input type="checkbox" class="mx-2 leading-6" ${ selected } data-value="${item.value}" data-text="${item.text}"
                data-action="multiselect#checkBoxChange" data-multiselect-target="item" tabindex="-1">
              <span>${item.text}</span>
            </label>
          </div>
        </div>      
      </li>
    `
  }

  checkBoxChange(event) {
    event.preventDefault()
    this.searchTarget.focus()
    this.toggleItem(event.currentTarget)
  }

  get placeholderTemplate() {
    return `
        <div class="my-1 flex items-center justify-center rounded border-[.5px] border-transparent bg-transparent px-2.5 py-1.5 text-sm font-medium">
          <div class="max-w-full flex-initial ">${this.element.dataset.placeholder}</div>
        </div>`
  }

  pillTemplate(item) {
    if (this.disabledValue) {
      return `
        <div  data-value="${item.value}" title="${item.text}" class="my-1 flex items-center justify-center rounded border-[.5px] border-stroke bg-gray px-2.5 py-1.5 text-sm font-medium dark:border-strokedark dark:bg-white/30">
          <div class="max-w-full flex-initial ">${item.text}</div>
        </div>`
    } else {
      return `
        <div data-value="${item.value}" title="${item.text}" class="my-1 flex items-center justify-center rounded border-[.5px] border-stroke bg-white px-2.5 py-1.5 text-sm font-medium dark:border-strokedark dark:bg-white/30">
          <div class="max-w-full flex-initial ">${item.text}</div>
          <a class="flex flex-auto flex-row-reverse" data-action="click->multiselect#removeItem">
            <span class="cursor-pointer pl-2 hover:text-danger">
              <svg class="fill-current" role="button" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.35355 3.35355C9.54882 3.15829 9.54882 2.84171 9.35355 2.64645C9.15829 2.45118 8.84171 2.45118 8.64645 2.64645L6 5.29289L3.35355 2.64645C3.15829 2.45118 2.84171 2.45118 2.64645 2.64645C2.45118 2.84171 2.45118 3.15829 2.64645 3.35355L5.29289 6L2.64645 8.64645C2.45118 8.84171 2.45118 9.15829 2.64645 9.35355C2.84171 9.54882 3.15829 9.54882 3.35355 9.35355L6 6.70711L8.64645 9.35355C8.84171 9.54882 9.15829 9.54882 9.35355 9.35355C9.54882 9.15829 9.54882 8.84171 9.35355 8.64645L6.70711 6L9.35355 3.35355Z" fill="currentColor"></path>
              </svg>
            </span>
          </a>
        </div>`
    }
  }
}

function debounce(fn, delay) {
  let timeoutId = null

  return (...args) => {
    const callback = () => fn.apply(this, args)
    clearTimeout(timeoutId)
    timeoutId = setTimeout(callback, delay)
  }
}

export { Multiselect }
