import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="accordion"
export default class extends Controller {
  static targets = [ "toggle", "menu" ];
  static classes = [ "toggle" ];

  connect() {
    this.closeAll();
    this.open(0);
  }

  toggle(event) {  
    const index = this.toggleTargets.findIndex((element) => element.contains(event.target));
    this.open(index);
  }

  open(index) {
    const hidden = this.menuTargets[index].classList.contains("hidden");
    this.closeAll();
    if (hidden) {
      this.toggleTargets[index].classList.add(...this.toggleClasses);
      this.menuTargets[index].classList.remove("hidden");
    }
  }

  closeAll() {
    this.toggleTargets.forEach((element, index) => {
      if(element.classList.contains(this.toggleClasses[0])) {
        element.classList.remove(...this.toggleClasses);
      }
    });

    this.menuTargets.forEach((element, index) => {
      if(!element.classList.contains("hidden")) {
        element.classList.add("hidden");
      }
    });
  }
}
